<!-- 新增应用 和 编辑应用-->
<template>
  <el-scrollbar>
    <HeaderRow></HeaderRow>
    <!-- 应用图标 -->
    <div class="icon-info-wrap">
      <div class="title">应用图标</div>
      <div class="upload-wrap">
        <span class="tips"></span>
        <div class="upload-wrap-ctx">
          <!-- delete-icon、mask -->
          <div
            class="el-upload-list el-upload-list--picture-card"
            v-show="formIconInfo.imageUrl != ''"
          >
            <div class="el-upload-list__item is-success">
              <img
                class="flex-img__image"
                :src="formIconInfo.imageUrl"
                style="width: 100%;
                height: 100%;
                display: block;
                border-radius: 50%;"
              />
              <!-- check-icon -->
              <!-- <label class="el-upload-list__item-status-label">
                <i class="el-icon-upload-success el-icon-check"></i>
              </label> -->
              <span class="el-upload-list__item-actions">
                <!-- <span @click="handlePictureCardPreview()">
                  <i class="el-icon-zoom-in"></i>
                </span> -->
                <span
                  class="el-upload-list__item-delete"
                  v-show="formIconInfo.imageUrl == '' ? false : true"
                >
                  <i class="el-icon-delete" @click.stop="handleRemoveImg()"></i>
                </span>
              </span>
            </div>
          </div>
          <!-- 上传组件 -->
          <el-upload
            v-show="formIconInfo.imageUrl === ''"
            class="upload-demo"
            :show-file-list="false"
            action="/api/v1/file/simple/upload"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :http-request="httpImgUpload"
            :on-progress="handleAvatarProgress"
            :auto-upload="true"
            v-loading="uploadIconLoading"
            element-loading-text="文件上传中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.6)"
          >
            <!-- <div class="img-wrap" v-if="formIconInfo.imageUrl">
              <img :src="formIconInfo.imageUrl" class="avatar" />
            </div> -->
            <div class="des-info">
              <i class="el-icon-circle-plus"></i>
              <div class="el-upload__text des-text">上传应用图标</div>
              <div class="el-upload__text des-size-1">
                尺寸80*80<br />图片不超过500k
              </div>
            </div>
          </el-upload>
        </div>
      </div>
    </div>
    <!-- 应用信息 -->
    <div class="app-info-wrap">
      <p class="title">应用信息</p>
      <div class="form-wrap">
        <el-form
          :model="formAppInfo"
          class="form-wrap-el"
          ref="appInfoFormRefs"
          :rules="appInfoRules"
        >
          <div class="flex-row-center">
            <el-form-item class="form-input app-name" prop="name">
              <span class="tips"></span>
              <el-input
                v-model="formAppInfo.name"
                placeholder="请输入应用名称"
                maxlength="16"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item class="form-input app-category" prop="type">
              <span class="tips"></span>
              <el-select
                v-model="formAppInfo.type"
                placeholder="请选择应用类型"
              >
                <el-option
                  v-for="item in categorySelectOptions"
                  :key="item"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item class="form-input app-des" prop="description">
            <span class="tips"></span>
            <el-input
              v-model="formAppInfo.description"
              placeholder="请输入应用简介"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 部门信息 -->
    <div class="class-info-wrap">
      <p class="title">部门信息</p>
      <div class="form-wrap">
        <el-form
          :model="formClassInfo"
          class="form-wrap-el"
          ref="classInfoFormRefs"
          :rules="classInfoRules"
        >
          <div class="flex-row-center">
            <el-form-item class="form-input app-name" prop="department">
              <span class="tips"></span>
              <el-input
                v-model="formClassInfo.department"
                placeholder="请输入负责部门"
                maxlength="16"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item class="form-input app-category" prop="admin">
              <span class="tips"></span>
              <!-- <el-select
                v-model="formClassInfo.admin"
                placeholder="请选择管理员"
                remote
                multiple
                filterable
                :remote-method="httpAdminSelectOption"
                :loading="adminSelectLoading"
                value-key="id"
                loading-text="查询中"
                no-match-text="未找到管理员"
                no-data-text="无数据"
              >
                <el-option
                  v-for="item in adminSelectOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select> -->
              <el-autocomplete
                v-model="formClassInfo.admin"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入管理员姓名"
                @select="handleSelectAdminName"
                :debounce="1000"
              ></el-autocomplete>
            </el-form-item>
          </div>
          <el-form-item class="form-input app-link" prop="link">
            <span class="tips"></span>
            <el-input
              v-model="formClassInfo.link"
              placeholder="请输入连接"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item class="form-input app-area" prop="scope">
            <span class="tips"></span>
            <el-select
              v-model="formClassInfo.scope"
              multiple
              placeholder="请选择可见范围"
            >
              <el-option
                v-for="item in scopeSelectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 按钮提交 -->
    <div class="btn-wrap">
      <BaseButton type="btn" size="btn-large" @click="handleClickSubmitApp">{{
        pageStatus == "addApp" ? "确定" : "保存"
      }}</BaseButton>
      <BaseButton type="btn-default" size="btn-large" @click="handleClickCancel"
        >取消</BaseButton
      >
    </div>
  </el-scrollbar>
</template>

<script>
import {
  createApp,
  reactive,
  ref,
  toRefs,
  onBeforeMount,
  onMounted,
  getCurrentInstance,
  watch,
} from "vue";
import BaseButton from "@/views/Admin/components/BaseButton";
import router from "@/router";
import {
  appAsk,
  appGet,
  appEdit,
  searchUserLikeName,
  setType,
  setAllByType,
} from "@/utils/api";
import { getUserInfo } from "@/utils/tools";
import { setTypes } from "@/utils/constant";
import { useRoute, useRouter } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";
import HeaderRow from "@/views/Admin/components/HeaderRow";
export default {
  name: "",
  components: {
    BaseButton,
    HeaderRow
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const appInfoFormRefs = ref(null);
    const classInfoFormRefs = ref(null);
    const state = reactive({
      // 上传图片loading
      uploadIconLoading: false,
      // 判断新增或编辑页面
      pageStatus: "",
      // 上传icon成功返回的img
      formIconInfo: {
        imageUrl: "",
      },
      // 应用信息
      formAppInfo: {
        name: "",
        type: "",
        description: "",
      },
      // 部门信息
      formClassInfo: {
        department: "",
        admin: "",
        link: "",
        scope: "",
      },
      // select加载管理员options
      adminSelectLoading: false,
      // 部门信息 - 管理员用户信息缓存
      formClassAdminTemp: "",
      // 类别 select options
      categorySelectOptions: [],
      // 管理员 select options
      // adminSelectOptions: [],
      // 范围
      scopeSelectOptions: [
        {
          value: "1",
          label: "范围字段1",
        },
        {
          value: "2",
          label: "范围字段2",
        },
      ],
      // 应用信息表单校验规则
      appInfoRules: {
        name: [
          { required: true, message: "请输入应用名称", trigger: "change" },
          { max: 16, message: "长度在16个字符以内", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择应用类型", trigger: "change" },
        ],
        description: [
          { required: true, message: "请输入应用简介", trigger: "change" },
        ],
      },
      // 部门信息表单校验规则
      classInfoRules: {
        department: [
          { required: true, message: "请输入负责部门", trigger: "change" },
          { max: 16, message: "长度在16个字符以内", trigger: "change" },
        ],
        admin: [{ required: true, message: "请选择管理员", trigger: "blur" }],
        link: [{ required: true, message: "请输入连接", trigger: "change" }],
        scope: [{ required: true, message: "请选择可见范围", trigger: "blur" }],
      },
      // 部门信息下管理员el-autocomplete
      querySearchAsync(query, cb) {
        if (query !== "") {
          state.adminSelectLoading = true;
          searchUserLikeName({ name: query }).then((res) => {
            state.adminSelectLoading = false;
            const data = res.data.map((item) => {
              return { value: item.name, ...item };
            });
            cb(data);
          });
        }
      },
      // 部门信息下管理员选中
      handleSelectAdminName(item) {
        state.formClassAdminTemp = { ...item };
        // console.log(JSON.stringify(item), state.formClassAdminTemp);
      },
      // 新增应用时搜索管理员
      // httpAdminSelectOption(query) {
      //   console.log(query);
      //   if (query !== "") {
      //     state.adminSelectLoading = true;
      //     searchUserLikeName({ name: query }).then((res) => {
      //       state.adminSelectLoading = false;
      //       state.adminSelectOptions = res.data.map((item) => {
      //         return { value: item.name, label: item.name, id: item.id };
      //       });
      //       console.log(JSON.parse(JSON.stringify(state.adminSelectOptions)));
      //     });
      //   } else {
      //     state.adminSelectOptions = [];
      //   }
      // },
      beforeAvatarUpload(file) {
        console.log("上传成功前", file);
        const isJPG = file.type === "image/jpeg" || file.type === "image/png";
        const isLt2M = file.size / 1024 < 100;
        if (!isJPG) {
          ElMessage.error("上传头像图片只能是 JPG/PNG 格式!");
        }
        if (!isLt2M) {
          ElMessage.error("上传头像图片大小不能超过 100KB!");
        }
        return isJPG && isLt2M;
      },
      handleAvatarProgress(res, file) {
        console.log(`上传中`);
        state.formIconInfo.imageUrl = res.data;
      },
      handleAvatarSuccess(res, file) {
        console.log(`上传成功`);
        state.formIconInfo.imageUrl = res.data;
      },
      // 移除，重新上传
      handleRemoveImg() {
        state.formIconInfo.imageUrl = "";
      },
      // axios上传图片
      httpImgUpload(item) {
        state.uploadIconLoading = true;
        const formData = new FormData();
        formData.append("file", item.file);
        // 定义上传图片的axios实例
        let serviceInstance = axios.create({
          baseURL: "http://staff-test.beizeng.net",
          timeout: 300000,
        });
        const token = window.localStorage.getItem("token");
        serviceInstance
          .post("/api/v1/file/simple/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-authrization": token,
            },
          })
          .then((res) => {
            if (res.data.code === 0) {
              ElMessage.success("上传icon成功");
              state.uploadIconLoading = false;
              const cbImgUrl = res.data.data;
              state.formIconInfo.imageUrl = cbImgUrl;
            }
          })
          .catch((err) => {
            ElMessage.success("上传icon失败");
            state.uploadIconLoading = false;
          });
      },
      // 新增应用--确定按钮 --- 编辑应用--保存按钮
      handleClickSubmitApp() {
        appInfoFormRefs.value.validate((valid) => {
          if (valid) {
            console.log(" appInfoFormRefs submit!", valid);
          } else {
            console.log(" appInfoFormRefs error submit!!");
            return false;
          }
        });
        classInfoFormRefs.value.validate((valid) => {
          if (valid) {
            console.log(" classInfoFormRefs submit!", valid);
          } else {
            console.log(" classInfoFormRefs error submit!!");
            return false;
          }
        });
        if (state.formIconInfo.imageUrl === "") {
          ElMessage.error("请上传应用图标!");
        } else {
          // 校验完毕，提交新增应用
          state.submitAppAsk();
        }
      },
      submitAppAsk() {
        console.log(
          route.path,
          state.formIconInfo,
          state.formAppInfo,
          state.formClassInfo
        );
        let { imageUrl } = state.formIconInfo;
        let { name, type, description } = state.formAppInfo;
        let { department, admin, link, scope } = state.formClassInfo;
        let userInfo = getUserInfo();
        let currSelectAdminInfo = state.formClassAdminTemp;
        let templateParams = {
          appkey: "adjaodioad",
          base: {
            created: "",
            creatorName: "",
            creatorNo: "",
            updated: "",
            updaterName: "",
            updaterNo: "",
          },
          description: "",
          icon: "",
          level: 1,
          name: "",
          page: "",
          secret: "qweidi",
          status: 1,
          type: "",
        };
        // templateParams.department = department;
        // templateParams.base.admin = admin;
        // templateParams.scope = scope;
        // templateParams.base.depts = userInfo.depts;
        templateParams.icon = imageUrl;
        templateParams.name = name;
        templateParams.type = type;
        templateParams.description = description;
        templateParams.page = link;
        templateParams.base.creatorName = userInfo.name;
        templateParams.base.creatorNo = userInfo.userId;
        templateParams.base.updaterName = admin;
        console.log(templateParams);
        if (route.path == "/admin/apps/add") {
          // 创建应用逻辑
          templateParams.base.updaterNo = userInfo.userId;
          templateParams.base.updaterName = userInfo.name;
          appAsk(templateParams)
            .then((res) => {
              console.log("新增应用Result-----", res);
              if (res.code === 0 && res.success) {
                router.push("/admin/apps/list");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (route.path.indexOf("/admin/apps/edit") >= 0) {
          // 编辑保存应用
          let { appkey } = route.params;
          templateParams.base.updaterNo = userInfo.userId;
          templateParams.base.updaterName = userInfo.name;
          appEdit(appkey, templateParams)
            .then((res) => {
              console.log("编辑保存应用Result-----", res);
              if (res.code === 0 && res.success) {
                router.push("/admin/apps/list");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      // 取消按钮-新增应用
      handleClickCancel() {
        // 重置表单
        state.formIconInfo.imageUrl = "";
        appInfoFormRefs.value.resetFields();
        classInfoFormRefs.value.resetFields();
        router.push("/admin/apps");
        // state.formAppInfo = {
        //   name: "",
        //   type: "",
        //   description: "",
        // };
        // state.formClassInfo = {
        //   department: "",
        //   admin: "",
        //   link: "",
        //   scope: "",
        // };
      },
    });
    // 编辑页面 --请求当前应用信息，并回显form内容
    const getCurrentAppInfo = async (appkey) => {
      try {
        let res = await appGet(appkey);
        if (res.code === 0 && res.success) {
          /*
            {
                "appkey": "0b4f2bbc463d4e97aad53638beda54a2",
                "base": {
                    "created": "2021-04-19 06:09:08",
                    "creatorName": "陈航",
                    "creatorNo": "19174255471225954",
                    "updated": "2021-04-19 06:09:08",
                    "updaterName": "陈航",
                    "updaterNo": "19174255471225954"
                },
                "description": "用户中心",
                "icon": "http://a.com/icon?id=xxxxxxx",
                "level": 0,
                "name": "",
                "page": "http://a.com/ticket/",
                "secret": "327f759882b745789f744fe9b0896604",
                "status": 0,
                "type": 0
            }
         */
          let { icon, name, type, base, level, description, page } = res.data;
          state.formIconInfo.imageUrl = icon;
          state.formAppInfo.name = name;
          state.formAppInfo.type = type;
          state.formAppInfo.description = description;
          state.formClassInfo.department = base.creatorName;
          state.formClassInfo.admin = base.creatorName;
          state.formClassInfo.link = page;
          state.formClassInfo.scope = level;
        }
      } catch (error) {
        console.log(error);
      }
    };
    // 新增应用 -- 请求应用类型Select配置项
    const getSelectAppType = async (type) => {
      try {
        let res = await setAllByType(type);
        return res.data.map((item) => ({
          // 后端test接口type重复,此处用id区别
          value: item.id,
          label: item.name,
          id: item.id,
        }));
      } catch (error) {
        console.log(error);
      }
    };
    // watch(state.adminSelectOptions, (newValue, oldValue) => {
    //   //直接监听
    //   console.log("count改变了", newValue, oldValue);
    // });
    onBeforeMount(async () => {
      console.log("获取路由参数", route.name, route.params);
      // 根据constant/setType自定义字段，获取当前应用类型分类
      state.categorySelectOptions = await getSelectAppType(setTypes.app);
      let { name } = route;
      if (name === "appEdit") {
        state.pageStatus = "editApp";
        let { appkey } = route.params;
        // 根据appkey获取当前应用详情
        getCurrentAppInfo(appkey);
      } else if (name === "addApp") {
        state.pageStatus = "addApp";
      }
    });
    onMounted(() => {});
    const refData = toRefs(state);
    return {
      appInfoFormRefs,
      classInfoFormRefs,
      ...refData,
    };
  },
};
</script>

<style scoped lang="scss">
::v-deep(.el-form-item__error) {
  left: 30px;
}
.flex-row-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
// 标题
.title {
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #f4f4f4;
  padding: 25px;
  font-size: 18px;
  font-family: SourceRegular;
  font-weight: 400;
  color: #555555;
}
span.tips {
  display: block;
  width: 6px;
  height: 6px;
  background: #ee472f;
  border-radius: 50%;
  margin-right: 20px;
}
// 图标上传
.icon-info-wrap {
  width: 100%;
  height: 349px;
  background: #ffffff;
  border-radius: 4px;
  margin: 20px 0;
  .upload-wrap {
    &-ctx {
      display: flex;
      ::v-deep(.el-upload-list__item) {
        background-color: #fff;
        border: 2px solid #f8f8f8;
      }
    }
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    margin: 30px 0 0 50px;
    &::v-deep(.upload-demo) {
      .el-upload,
      .el-upload-dragger {
        width: 200px;
        height: 200px;
        background: #f8f8f8;
        border-radius: 2px;
        border: none;
        .img-wrap {
          width: 100%;
          height: 100%;
          img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .des-info {
          height: 100%;
          display: flex;
          flex-direction: column;
          .el-icon-circle-plus {
            margin: 20px auto 6px;
            font-size: 34px;
            color: #227fff;
          }
          .el-upload__text {
            margin: 10px 0;
            &:first-child {
              font-size: 16px;
              font-family: SourceRegular;
              font-weight: 400;
              color: #a5a9af;
              line-height: 23px;
            }
          }
          .des-text {
            font-size: 16px;
            font-family: SourceRegular;
            font-weight: 400;
            color: #a5a9af;
            line-height: 23px;
          }
          .des-size-1 {
            font-size: 14px;
            font-family: SourceLight;
            font-weight: 300;
            color: #c0c4c9;
            line-height: 23px;
          }
        }
      }
    }
  }
}
// 应用信息
.app-info-wrap,
.class-info-wrap {
  height: 295px;
  background: #ffffff;
  margin: 20px 0;
  .form-wrap {
    margin: 50px 36px;
    // background: #f8f8f8;
    &::v-deep(.form-wrap-el) {
      .app-name,
      .app-des,
      .app-link {
        width: 48%;
        height: 61px;
        .el-form-item__content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          // input
          .el-input__inner {
            background: #f8f8f8;
            height: 61px;
            width: 100%;
            &::placeholder {
              font-size: 16px;
              font-family: SourceNormal;
              font-weight: 400;
              color: #a5a9af;
            }
          }
          .el-input__suffix .el-input__count-inner {
            background: #f8f8f8;
            font-size: 16px;
            font-family: SourceRegular;
            font-weight: 400;
            color: #a5a9af;
            margin-right: 26px;
          }
        }
      }
      .app-category,
      .app-area {
        width: 48%;
        height: 61px;
        .el-form-item__content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          & .el-autocomplete {
            height: 100%;
            width: 100%;
            .el-input {
              height: 100%;
              width: 100%;
              // 下拉框
              .el-input__inner {
                height: 100%;
                width: 100%;
                background: #f8f8f8;

                &::placeholder {
                  font-size: 16px;
                  font-family: SourceNormal;
                  font-weight: 400;
                  color: #a5a9af;
                }
              }
              .el-input__suffix {
                margin-right: 20px;
                // 下拉icon
                .el-select__caret {
                  color: #333;
                  font-size: 16px;
                  font-weight: bold;
                }
              }
            }
          }
          & .el-select {
            height: 100%;
            width: 100%;
            & .select-trigger {
              height: 100%;
              width: 100%;
              .el-input {
                height: 100%;
                width: 100%;
                // 下拉框
                .el-input__inner {
                  height: 100%;
                  width: 100%;
                  background: #f8f8f8;

                  &::placeholder {
                    font-size: 16px;
                    font-family: SourceNormal;
                    font-weight: 400;
                    color: #a5a9af;
                  }
                }
                .el-input__suffix {
                  margin-right: 20px;
                  // 下拉icon
                  .el-select__caret {
                    color: #333;
                    font-size: 16px;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
      // 重置长度
      .app-des,
      .app-link,
      .app-area {
        width: 98% !important;
        margin: 0px auto;
      }
    }
  }
}
// 部门信息
.class-info-wrap {
  height: 410px !important;
  background: #ffffff;
  margin: 20px 0;
  .app-area {
    margin: 30px auto !important;
  }
}

// // 部门信息
// .class-info-wrap {
//   height: 410px;
//   background: #ffffff;
//   margin: 20px 0;
//   // wrap
//   .form-wrap {
//     margin: 50px 36px;
//     .form-input {
//       // background: #f8f8f8;
//       border-radius: 2px;
//       & ::v-deep .el-form-item__content {
//         display: flex;
//         flex-direction: row;
//         justify-content: center;
//         align-items: center;
//         // input
//         .el-input {
//           ::placeholder {
//             font-size: 16px;
//             font-family: SourceNormal;
//             font-weight: 400;
//             color: #a5a9af;
//           }
//           .el-input__inner {
//             background: #f8f8f8;
//           }
//         }
//       }

//       ::v-deep .el-select {
//         width: 100%;
//         height: 51px;
//         // background: #f8f8f8;
//         // 下拉icon
//         .el-input .el-select__caret {
//           color: #333;
//           font-size: 14px;
//           font-weight: bold;
//         }
//       }
//     }
//   }

//   .app-name {
//     width: 48%;
//     margin-bottom: 0;
//   }
//   .app-category {
//     width: 48%;
//     margin-bottom: 0;
//   }
//   .app-des1,
//   .app-des2 {
//     width: 98%;
//     margin: 30px auto;
//   }
// }

// 按钮
.btn-wrap {
  height: 200px;
  margin: 40px 0 0 70px;
  button {
    margin: 0 20px;
  }
}
</style>
